<template>
	<LoginLayout
		:form-title="formTitle"
		portal-name="Administration Portal"
	>
		<template #form>
			<div v-if="linkValid">
				<span
					v-if="passwordReset"
				>
					<a @click="redirectToLogin">
						Click here to login
					</a>
				</span>
				<validation-observer
					v-else
					#default="{ handleSubmit }"
					class="cassie-vertical-sm"
				>
					<PasswordField
						:id="String(Math.random())"
						v-model="password"
						:rules="{ password: true }"
						label="New Password *"
						field-name="New Password"
						@input="clearSubmissionAttempt"
					/>
					<PasswordField
						:id="String(Math.random())"
						v-model="confirmPassword"
						:rules="{ password: true, confirmed: 'New Password' }"
						label="Confirm New Password *"
						@input="clearSubmissionAttempt"
					/>
					<p class="text-left text-caption">
						Passwords must be at least 12 characters long, contain a number, an uppercase letter and at least 1 special character (e.g. ! @ # $ £ % ⌃)
					</p>
					<Message v-if="apiRespondedWithWeakPassword">
						This password is too weak
					</Message>
					<v-card-actions class="pa-0">
						<v-spacer />
						<PrimaryActionButton @click="handleSubmit(resetPassword)">
							Submit
						</PrimaryActionButton>
					</v-card-actions>
				</validation-observer>
			</div>
			<div v-else>
				<p>
					If you still require to set your password
					<a @click="redirectToForgottenPassword">
						please click here
					</a>
				</p>
			</div>
		</template>
	</LoginLayout>
</template>

<script>
import LoginLayout from '../../../../../shared/layouts/login-layout.vue'
import PrimaryActionButton from '../../../../../shared/components/primary-action-button.vue'
import PasswordField from '../../../../../shared/components/password-field.vue'
import { resetAdminPortalPasswordApi, checkAdminPortalPasswordResetLinkValidity } from '../../../../../shared/utils/api/forgotten-password.js'
import Message from '../../../../../shared/components/message.vue'

export default {
	components: { Message, LoginLayout, PrimaryActionButton, PasswordField },
	props: {
		hash: {
			type: String,
			required: true
		}
	},
	data () {
		return {
			password: '',
			confirmPassword: '',
			apiRespondedWithWeakPassword: false,
			passwordReset: false,
			linkValid: null
		}
	},
	computed: {
		formTitle () {
			if (this.linkValid) {
				if (this.passwordReset) return 'Your password has been set.'
				else return 'Set Password'
			} else {
				return 'Your link has expired or already been used'
			}
		}
	},
	created () {
		this.checkResetPasswordLink()
	},
	methods: {
		async checkResetPasswordLink () {
			const { data: { valid } } = await checkAdminPortalPasswordResetLinkValidity(this.hash)
			this.linkValid = valid
		},
		clearSubmissionAttempt () {
			this.apiRespondedWithWeakPassword = false
		},
		redirectToForgottenPassword () {
			this.$router.push('forgotten-password')
		},
		redirectToLogin () {
			this.$router.push('login')
		},
		async resetPassword () {
			const { hash, password } = this
			try {
				await resetAdminPortalPasswordApi({ hash, password })
				this.apiRespondedWithWeakPassword = false
			} catch (_) {
				this.apiRespondedWithWeakPassword = true
			}
			if (!this.apiRespondedWithWeakPassword) {
				this.passwordReset = true
			}
		}
	}
}
</script>
